@import "../../../../assets/theme.scss";

$textColor: #ffffff;
$greenColor: #00c99f;

#public-navbar {
  .navbar li {
    .btn-signup {
      border-radius: 15px;
      border: solid 1px #ffffff;
      padding: 8px 20px;
      margin-top: 6px;
    }
  }

  .navbar-default {
    background-color: $greenColor;
    border: unset;
    border-radius: unset;
    padding: 5px 0px;
  }

  .navbar-default .navbar-nav>li>a {
    color: $textColor;
    font-family: $fontFamily;
    font-size: 17px;
  }

  .navbar-brand {
    margin-left: 15px;
    padding: 0px;
    img {
      height: 100%;
      width: auto;
      background-color: white;
    }
  }
}

@media (max-width: 767px){
  #public-navbar {
    .btn-signup {
      width: 100px;
      margin: auto;
    }
  }
}

@media (min-width: 768px) {
  #public-navbar {
    .right-links {
      float: right;
    }
  }
}
